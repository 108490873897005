<template>
  <div class="d-flex flex-column">
    <div>
      <v-row class="mt-0 mb-0" style="height:125px; background-color: #fee400;border-bottom: 3px solid red;">
        <v-col cols="2" style="height: 100%">
          <a href="https://occicom.fr/" target="_blank"><img src="./assets/images/Logo_OFFICIEL_2021.png" style="max-height: 100%" class="center-xy px-2 py-2" /></a>
        </v-col>
        <v-col cols="10" class="text-right" style="height: 100%">
          <div v-if="$vuetify.breakpoint.mdAndUp" style="height: 100%; display: inline-flex;" class="center-x pr-4">
            <div class="text-h6 text-right">
              <b><span class="red--text">Opérateur télécom local & réactif - Pour les Entreprises et les Collectivités</span>
              <br />
              Tarn / Tarn-et-Garonne : <span class="red--text">05 63 607 607</span> - Aveyron / Corrèze / Lozère : <span class="red--text">05 65 600 600</span> - Cantal : <span class="red--text">04 71 45 62 45</span>
              </b>
            </div>  
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-0">
      <v-col xs="12" sm="12" md="12" lg="8" xl="8" class="px-0 py-0" style="height: 88vh; overflow: auto;">
        <div class="pa-8 white--text" :style="`background-image: url(${background}); background-size: cover;`">
          <div class="text-h4" :class="$vuetify.breakpoint.smAndUp ? 'ml-16 mr-16' : ''">
            <b>Optez pour une <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">téléphonie simplifiée</span> et <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">sur-mesure</span> conçue pour les usages des professionnels.</b>
          </div>
          <div class="text-h4 mt-10 mb-10" :class="$vuetify.breakpoint.smAndUp ? 'ml-16 mr-16' : ''">
            <b>Faites le choix d'un <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">interlocuteur local</span> et engagé pour la <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">performance</span> des entreprises.
            </b>
          </div>
          <v-row class="mt-10 mb-10">
            <v-col style="text-align: center" xs="12" sm="6" md="3" lg="3" xl="3" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/internet_fibre.png"/>
              <div class="text-h5 text-center">Internet & fibre</div>
            </v-col>
            <v-col style="text-align: center" xs="12" sm="6" md="3" lg="3" xl="3" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/fixe.png"/>
              <div class="text-h5 text-center">Téléphonie fixe</div>
            </v-col>
            <v-col style="text-align: center" xs="12" sm="6" md="3" lg="3" xl="3" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/mobile.png"/>
              <div class="text-h5 text-center">Téléphonie mobile</div>
            </v-col>
            <v-col style="text-align: center" xs="12" sm="6" md="3" lg="3" xl="3" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/collaborative.png"/>
              <div class="text-h5 text-center">Téléphonie collaborative</div>
            </v-col>
          </v-row>
        </div>
        <div class="pa-8 mt-8">
          <v-row>
            <v-col xs="12" sm="12" md="7" lg="7" xl="7" cols="12" :class="$vuetify.breakpoint.smAndUp ? 'pl-16 pr-16' : ''">
              <div class="text-h3 red--text" style="line-height: inherit;">
                <b>Vos bénéfices</b>
              </div>
              <div class="text-h4 pb-6" style="line-height: inherit;">
                <b>Avec OcciCom.</b>
              </div>
              <div class="text-h5 mb-4">
                <span class="red--text">Rentabilité : </span> Une solution et un service pour <b>booster</b> votre <b>productivité.</b>
              </div>
              <div class="text-h5 mb-4">
                <span class="red--text">Simplicité : </span> <b>Gain de temps</b> pour vous et vos collaborateurs.
              </div>
              <div class="text-h5 mb-4">
                <span class="red--text">Image de marque : </span> Votre <b>professionnalisme</b> transmis grâce à la téléphonie.
              </div>
              <div class="text-h5 mb-4">
                <span class="red--text">Mobilité : </span> Une téléphonie <b>unifiée</b> disponible comme au bureau à tout endroit et à tout moment.
              </div>
            </v-col>
            <v-col xs="12" sm="12" md="5" lg="5" xl="5" cols="12" :style="`background-image: url(${backgroundFemme}); background-size: cover;`">
            </v-col>
          </v-row>
        </div>
        <div class="px-6" :style="`background-color:#fee400`">
          <div class="text-h3 red--text pl-14" style="line-height: inherit;">
            <b>Notre engagements :</b>
          </div>
          <div class="text-h4 pb-6 pl-14" style="line-height: inherit;">
            <b>Un service de proximité réactif.</b>
          </div>
          <v-row>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <div class="text-center">
                <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou1.png"/>
              </div>
              <div :class="$vuetify.breakpoint.smAndUp ? 'pl-16 pr-16' : ''">
                <div class="my-4 text-h4 text-center red--text">Etude préalable</div>
                <div class="text-h5 text-center">Audit de vos <b>installations existantes</b> et de votre <b>réseau</b> informatique et télécom</div>
                <div class="text-h5 text-center">Audit de vos <b>factures</b> opérateur et de vos <b>besoins</b></div>
              </div>
            </v-col>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <div class="text-center">
                <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou2.png"/>
              </div>
              <div :class="$vuetify.breakpoint.smAndUp ? 'pl-16 pr-16' : ''">
                <div class="my-4 text-h4 text-center red--text">Conseils d'experts</div>
                <div class="text-h5 text-center"><b>Conseils personnalisés</b> en fonction de vos besoins et de votre <b>éligibilité</b></div>
                <div class="text-h5 text-center">Conseils sur les évolutions technologiques, et les <b>économies</b> possibles</div>
              </div>
            </v-col>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <div class="text-center">
                <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou3.png"/>
              </div>
              <div :class="$vuetify.breakpoint.smAndUp ? 'pl-16 pr-16' : ''">
                <div class="my-4 text-h4 text-center red--text">Proposition adaptée</div>
                <div class="text-h5 text-center">Présentation de solutions téléphoniques <b>sur-mesure</b></div>
                <div class="text-h5 text-center">Présentation du budget, des délais et des <b>bénéfices client</b></div>
              </div>
            </v-col>
            <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <div class="text-center">
                <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou4.jpg"/>
              </div>
              <div :class="$vuetify.breakpoint.smAndUp ? 'pl-16 pr-16' : ''">
                <div class="my-4 text-h4 text-center red--text">Réalisation et suivi</div>
                <div class="text-h5 text-center">Mise en place des nouvelles installations et <b>formation</b> du personnel</div>
                <div class="text-h5 text-center">Réalisation <b>conforme</b> aux prévisions et études préalables</div>
                <div class="text-h5 text-center">Suivi <b>dédié</b> par un chef de projet dédié</div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="pa-8 mt-8" :class="$vuetify.breakpoint.smAndUp ? 'pl-16 pr-16' : ''">
          <div class="text-h3 red--text" style="line-height: inherit;">
            <b>Notre volonté :</b>
          </div>
          <div class="text-h4 pb-6" style="line-height: inherit;">
            <b>Optimiser votre quotidien</b>
          </div>
          <v-row>
            <v-col class="center-y" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/accompagnement.png"/>
              <div class="text-h5 text-center">En tant qu'opérateur <b>local</b>, nous sommes toujours <b>disponibles</b> et proches de vous pour la moindre demande.</div>
            </v-col>
            <v-col class="center-y" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/serenite.png"/>
              <div class="text-h5 text-center">Un service <b>d'accompagnement unique</b> de A à Z (de l'audit à l'installation et la maintenance de vos télécoms).</div>
            </v-col>
          </v-row>
          <v-row class="white--text" :style="`background-color:red`">
            <v-col class="center-y mt-4" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/simplicite.png"/>
              <div class="text-h5 text-center">Une offre globale, adaptée à vos besoins et un <b>meilleur contrôle</b> de vos communications grâce à la <b>supervision totale</b>.</div>
            </v-col>
            <v-col class="center-y mt-4" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/protection.png"/>
              <div class="text-h5 text-center">Profitez du <b>meilleur réseau</b> français.</div>
              <div class="text-h5 text-center"><b>Conservez</b> vos numéros, <b>restez joignables</b> sur tous vos appareils de communcation.</div>
            </v-col>
          </v-row>
        </div>
        <v-card v-if="$vuetify.breakpoint.mdAndDown" class="pa-4 mx-10 my-10">
          <div class="text-h4 text-center mt-8 mb-8">
            Nous vous proposons un audit <span class="red--text">gratuit</span> de votre téléphonie : 
          </div>
          <v-text-field v-model="entreprise" light solo label="Nom de la société" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="nom" name="name" light solo label="Nom" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="prenom" name="firstName" light solo label="Prénom" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="email" name="email" type="email" light solo label="Email" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="tel" name="phone" type="tel" light solo label="Téléphone" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="fixeAmount" name="fixeAmount" type="number" light solo label="Combien de téléphones fixes avez-vous ?" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="mobileAmount" name="mobileAmount" type="number" light solo label="Combien de téléphones mobiles avez-vous ?" hide-details class="mb-2"></v-text-field>
          <v-checkbox v-model="checkbox">
            <template v-slot:label>
              <div>
                J'ai lu et j'accepte la
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://www.occicom.fr/"
                      @click.stop
                      v-on="on"
                    >
                      politique de confidentialité d'Occicom
                    </a>
                  </template>
                  Ouvrir dans un autre onglet
                </v-tooltip>
                en matière de protection des données à caractère personnel.
              </div>
            </template>
          </v-checkbox>
          <v-btn :disabled="!isValid" :loading="loading" block @click="send">Je souhaite être rappelé</v-btn>
          <v-alert
            v-if="error"
            class="mt-4"
            type="error"
          >
            Il y a eu une erreur, veuillez réessayer plus tard
          </v-alert>
          <v-alert
            v-if="success"
            class="mt-4"
            type="success"
          >
            Sauvegarde réussite, merci de votre confiance !
          </v-alert>
        </v-card>
        <div>
          <v-row class="mt-0 mb-0" style="height:125px; background-color: #fee400;border-bottom: 3px solid red;">
            <v-col cols="12" class="text-right" style="height: 100%">
              <div v-if="$vuetify.breakpoint.mdAndUp" style="height: 100%; display: inline-flex;" class="center-x pr-4">
                <div class="text-h6 text-right">
                  <b><span class="red--text">Opérateur télécom local & réactif - Pour les Entreprises et les Collectivités</span>
                  <br />
                  Tarn / Tarn-et-Garonne : <span class="red--text">05 63 607 607</span> - Aveyron / Corrèze / Lozère : <span class="red--text">05 65 600 600</span> - Cantal : <span class="red--text">04 71 45 62 45</span>
                  </b>
                </div>  
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col v-if="!$vuetify.breakpoint.mdAndDown" style="overflow: auto; height: 80vh;" xs="12" sm="12" md="12" lg="4" xl="4">
        <v-card class="pa-4">
          <div class="text-h4 text-center mt-8 mb-8">
            Nous vous proposons un audit <span class="red--text">gratuit</span> de votre téléphonie : 
          </div>
          <v-text-field v-model="entreprise" light solo label="Nom de la société" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="nom" name="name" light solo label="Nom" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="prenom" name="firstName" light solo label="Prénom" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="email" name="email" type="email" light solo label="Email" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="tel" name="phone" type="tel" light solo label="Téléphone" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="fixeAmount" name="fixeAmount" type="number" light solo label="Combien de téléphones fixes avez-vous ?" hide-details class="mb-2"></v-text-field>
          <v-text-field v-model="mobileAmount" name="mobileAmount" type="number" light solo label="Combien de téléphones mobiles avez-vous ?" hide-details class="mb-2"></v-text-field>
          <v-checkbox v-model="checkbox">
            <template v-slot:label>
              <div>
                J'ai lu et j'accepte la
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://www.occicom.fr/"
                      @click.stop
                      v-on="on"
                    >
                      politique de confidentialité d'Occicom
                    </a>
                  </template>
                  Ouvrir dans un autre onglet
                </v-tooltip>
                en matière de protection des données à caractère personnel.
              </div>
            </template>
          </v-checkbox>
          <v-btn :disabled="!isValid" :loading="loading" block @click="send">Je souhaite être rappelé</v-btn>
          <v-alert
            v-if="error"
            class="mt-4"
            type="error"
          >
            Il y a eu une erreur, veuillez réessayer plus tard
          </v-alert>
          <v-alert
            v-if="success"
            class="mt-4"
            type="success"
          >
            Sauvegarde réussite, merci de votre confiance !
          </v-alert>
        </v-card>
      </v-col>
      
    </v-row>
  </div>
</template>

<script>
import background from './assets/images/background.jpeg'
import backgroundFemme from './assets/images/femme.jpeg'

export default {
  components: { },
  name: 'general',

  data () {
    return {
      background: background,
      backgroundFemme: backgroundFemme,
      prenom: null,
      nom: null,
      tel: null,
      entreprise: null,
      email: null,
      fixeAmount: null,
      mobileAmount: null,
      checkbox: false,
      loading: false,
      error: false,
      success: false
    }
  },

  computed: {
    isValid: function() {
      return (this.prenom != null && this.prenom.length > 1)
        && (this.nom != null && this.nom.length > 1)
        && (this.tel != null && this.tel.length > 1)
        && (this.entreprise != null && this.entreprise.length > 1)
        && (this.email != null && this.email.length > 1)
        && (this.fixeAmount != null)
        && (this.mobileAmount != null)
        && this.checkbox == true
    }
  },

  methods: {
    send: function() {
      this.loading = true;
      this.error = this.success = false;
      
      this.$http.post(this.$env.api.host + "/formsData", {
        form: 3,
        title: "Général",
        value: JSON.stringify(this.getValue())
      })
      .then(() => {
        this.loading = false;
        this.success = true;
        this.reset();
      })
      .catch((error) => {
        console.error(error);
        this.error = true;
        this.loading = false;
      });
    },
    getValue: function() {
      return {
        prenom: this.prenom,
        nom: this.nom,
        tel: this.tel,
        email: this.email,
        fixeAmount: this.fixeAmount,
        mobileAmount: this.mobileAmount,
        entreprise: this.entreprise,
        checkbox: this.checkbox
      }
    },
    reset: function() {
      this.prenom = null;
      this.nom = null;
      this.tel = null;
      this.entreprise = null;
      this.email = null;
      this.fixeAmount = null;
      this.mobileAmount = null;
      this.checkbox = false;
    }
  }
};
</script>