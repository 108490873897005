<template>
  <div style="background-color: #F8F7F3;">
    <v-carousel
      hide-delimiters
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item>
        <v-sheet
          height="100%"
        >
          <v-row
            class="fill-height pa-4"
            align="center"
            justify="center"
          >
            <v-col xl="3" lg="3" md="3" sm="3" cols="1"></v-col>
            <v-col xl="6" lg="6" md="6" sm="6" cols="10" class="text-center">        
              <div class="text-h3 transition-swing mb-4 mt-4">
                Tutoriels Espace client
              </div>
              <div class="text-h5 transition-swing mb-4 mt-4">
                Retrouvez nos tutoriels d'aide sur votre Espace client
              </div>
              <div class="text-subtitle-1 transition-swing mb-4 mt-4">
                Parcourez l’ensemble des tutoriels et choisissez le tutoriel vidéo qui répondra à votre problématique.
              </div>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="3" cols="1">
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div v-if="validationLoading && validation == null" class="text-center pb-3">
       <v-btn
          x-large
          loading
          disabled
          color="blue-grey"
          class="ma-2 white--text"
          fab
        >
          <v-icon dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
    </div>
    <div v-if="validationLoading && validation == true" class="d-flex mt-10">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1"></v-col>
      <v-col xl="10" lg="10" md="10" sm="12" cols="12">
        <div class="text-h2 transition-swing mb-4 mt-4 text-center">
          Comment pouvons-nous vous aider ?
        </div>
        <v-item-group>
          <v-container>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoCarou
                    title="Se connecter à mon espace client OcciCom" 
                    sub="Ouvrir le tutoriel"
                    :images="[{ id: 0, src: Carou1img1}, { id: 1, src: Carou1img2 }, { id: 2, src: Carou1img3 }, { id: 3, src: Carou1img4 }, { id: 4, src: Carou1img5 }, { id: 5, src: Carou1img6 }]"
                    />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoCarou
                    title="Récupérer mes factures" 
                    sub="Ouvrir le tutoriel"
                    :images="[{ id: 0, src: Carou2img1}, { id: 1, src: Carou2img2 }, { id: 2, src: Carou2img3 }]"
                    />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoCarou
                    title="Modification des touche rapide du poste téléphonique" 
                    sub="Ouvrir le tutoriel"
                    :images="[{ id: 0, src: Carou3img1}, { id: 1, src: Carou3img2 }, { id: 2, src: Carou3img3 }, { id: 3, src: Carou3img4 }, { id: 4, src: Carou3img5 }, { id: 5, src: Carou3img6 }, { id: 6, src: Carou3img7}, { id: 7, src: Carou3img8 }, { id: 8, src: Carou3img9 }, { id: 9, src: Carou3img10 }, { id: 10, src: Carou3img11 }]"
                    />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoCarou
                    title="Programmer un renvoi d'appel" 
                    sub="Ouvrir le tutoriel"
                    :images="[{ id: 0, src: Carou4img1}, { id: 1, src: Carou4img2 }, { id: 2, src: Carou4img3 }, { id: 3, src: Carou4img4 }, { id: 4, src: Carou4img5 }, { id: 5, src: Carou4img6 }, { id: 6, src: Carou4img7 }]"
                    />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Récupération des factures" 
                    sub="Ce tutoriel vous montre comment récupérer vos factures depuis votre espace client"
                    link="https://www.youtube.com/embed/b_9ZutbcXSY" />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Modification touche rapide poste téléphonique" 
                    sub="Ce tutoriel vous montre comment modifier une ou plusieurs touche rapide de votre poste téléphonique fixe."
                    link="https://www.youtube.com/embed/T88YAoV_fYw" />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Renvois d'appels" 
                    sub="Ce tutoriel vous montre les différentes options pour renvoyer les appels de votre poste vers un autre numéro."
                    link="https://www.youtube.com/embed/_JJc2yGIHeE" />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Modifier les horaires d’acueil" 
                    sub="Ce tutoriel vous montre comment modifier les horaires d’acueil."
                    link="https://www.youtube.com/embed/KPGVfliR3Vw" />
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1"></v-col>
    </div>
    <div v-if="validationLoading && validation == false" class="d-flex">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="text-h4 transition-swing mb-4 mt-4 text-center">
          Vous devez être connecté à votre compte Occicom pour avoir accès à nos services
        </div>
        <v-btn block dark color="#df101e" x-large to="/login">Connexion</v-btn>
      </v-col>
      <v-col cols="1"></v-col>
    </div>
  </div>
</template>

<script>
import W3TelValidation from '../../../mixins/W3TelValidation'

import TutoVideo from '../../../components/App/TutoVideo';

import TutoCarou from '../../../components/App/TutoCarou';

import Carou1img1 from './carou/1/1.png';
import Carou1img2 from './carou/1/2.png';
import Carou1img3 from './carou/1/3.png';
import Carou1img4 from './carou/1/4.png';
import Carou1img5 from './carou/1/5.png';
import Carou1img6 from './carou/1/6.png';

import Carou2img1 from './carou/2/1.png';
import Carou2img2 from './carou/2/2.png';
import Carou2img3 from './carou/2/3.png';

import Carou3img1 from './carou/3/1.png';
import Carou3img2 from './carou/3/2.png';
import Carou3img3 from './carou/3/3.png';
import Carou3img4 from './carou/3/4.png';
import Carou3img5 from './carou/3/5.png';
import Carou3img6 from './carou/3/6.png';
import Carou3img7 from './carou/3/7.png';
import Carou3img8 from './carou/3/8.png';
import Carou3img9 from './carou/3/9.png';
import Carou3img10 from './carou/3/10.png';
import Carou3img11 from './carou/3/11.png';

import Carou4img1 from './carou/4/1.png';
import Carou4img2 from './carou/4/2.png';
import Carou4img3 from './carou/4/3.png';
import Carou4img4 from './carou/4/4.png';
import Carou4img5 from './carou/4/5.png';
import Carou4img6 from './carou/4/6.png';
import Carou4img7 from './carou/4/7.png';

export default {
  name: 'Tutoriels',

  mixins: [W3TelValidation],

  components: { TutoVideo, TutoCarou },

  data () {
    return {
      Carou1img1: Carou1img1,
      Carou1img2: Carou1img2,
      Carou1img3: Carou1img3,
      Carou1img4: Carou1img4,
      Carou1img5: Carou1img5,
      Carou1img6: Carou1img6,
      Carou2img1: Carou2img1,
      Carou2img2: Carou2img2,
      Carou2img3: Carou2img3,
      Carou3img1: Carou3img1,
      Carou3img2: Carou3img2,
      Carou3img3: Carou3img3,
      Carou3img4: Carou3img4,
      Carou3img5: Carou3img5,
      Carou3img6: Carou3img6,
      Carou3img7: Carou3img7,
      Carou3img8: Carou3img8,
      Carou3img9: Carou3img9,
      Carou3img10: Carou3img10,
      Carou3img11: Carou3img11,
      Carou4img1: Carou4img1,
      Carou4img2: Carou4img2,
      Carou4img3: Carou4img3,
      Carou4img4: Carou4img4,
      Carou4img5: Carou4img5,
      Carou4img6: Carou4img6,
      Carou4img7: Carou4img7,
    }
  },

  mounted: function() {
    this.validate();
  }
};
</script>