<template>
  <div class="d-flex flex-column">
    <div>
      <v-row class="mt-0 mb-0" style="height:125px; background-color: #fee400;border-bottom: 3px solid red;">
        <v-col cols="3" style="height: 100%">
          <img src="./assets/images/Logo_OFFICIEL_2021.png" style="max-height: 100%" class="center-xy px-2 py-2" />
        </v-col>
        <v-col cols="1" style="height: 100%">

        </v-col>
        <v-col cols="8" class="text-right" style="height: 100%">
          <div v-if="$vuetify.breakpoint.smAndUp" style="height: 100%; display: inline-flex;" class="center-x pr-4">
            <img v-if="!$vuetify.breakpoint.mdAndDown" src="./assets/images/simcard.png" style="max-height: 100%" class="center-xy px-2 py-2" />
            <div class="text-h5 text-right">
              <b>OFFRE PROMO : <span class="red--text">FORFAIT 50Go - Réseau 5G - Appels en WIFI</span>
              <br />
              25€ HT/mois* <span class="red--text">au lieu de</span> 30€HT/mois</b>
              <br/>
              <div class="text-caption">
                *hors convergence fixe/mobile et compagnon
              </div>
            </div>  
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-0">
      <v-col xs="12" sm="12" md="12" lg="8" xl="8" class="px-0 py-0" style="height: 88vh; overflow: auto;">
        <div class="pa-8" :style="`background-image: url(${background}); background-size: cover;`">
          <div class="text-h3 pb-6 white--text" style="line-height: inherit;">
            Optez pour des <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">solutions mobiles</span> conçues <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">sur-mesure</span> pour les usages des professionnels.
          </div>
          <div class="text-h3 pb-10 white--text" style="line-height: inherit;">
            Faites le choix d'un <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">interlocuteur local</span> et engagé pour la <span class="px-2 red--text yellow font-weight-bold" style="border-radius: 5px;">performance</span> des entreprises.
          </div>
          <div class="text-h3 pt-10 pb-6 white--text">
            Offre PROMO
          </div>
          <div class="text-h3 pb-6 white--text font-weight-bold">
            <span class="px-2 red--text yellow" style="border-radius: 5px;">FORFAIT PRO</span> 5G
          </div>
          <div class="text-h3 pb-6 white--text font-weight-bold">
            50Go à <span class="px-2 red--text yellow" style="border-radius: 5px;">25€HT</span> au lieu de 30€HT
          </div>
          <div class="text-h3 pb-6 white--text font-weight-bold" style="display: flex; align-items: center;">
            Voix sur <span><img style="height: 100px; " src="./assets/images/wifi.png"/></span>
            <span class="text-caption">
              <span>Itinérance des données incluse :</span>
              <br/><span>(Union Européenne + Suisse & Andorre)</span>
            </span>
          </div>
        </div>
        <div class="mx-8">
          <div class="text-h3 pt-6 pb-6 red--text">
            Boostez votre productivité et bien plus.
          </div>
          <div class="text-h5 pt-6 pb-6">
            En passant par Occicom, vous choisissez le <span class="red--text">réseau français n°1</span>, tout en profitant de notre <span class="red--text">expertise</span> et notre <span class="red--text">réactivité</span>.
          </div>
          <v-row>
            <v-col :class="!$vuetify.breakpoint.mdAndDown ? 'center-y' : ''" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou1.jpg"/>
              <div class="my-4 text-h4 text-center">Des forfaits adaptés</div>
              <div class="text-h5 text-center">Appels, SMS, MMS <b>illimités</b>, jusqu'à 250Go d'internet.</div>
              <div class="text-h5 text-center"><b>Déployez</b> votre mobilité avec des abonnements qui <b>s'adaptent</b> à votre utilisation et à <b>vos objectifs</b>.</div>
            </v-col>
            <v-col :class="!$vuetify.breakpoint.mdAndDown ? 'center-y' : ''" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou2.png"/>
              <div class="my-4 text-h4 text-center">Des services avancés</div>
              <div class="text-h5 text-center">Profitez de la <b>puissance</b> de la <b>5G</b>.</div>
              <div class="text-h5 text-center">Passez vos appels en <b>WIFI</b>.</div>
              <div class="text-h5 text-center">Restez <b>joignable</b> à l'étranger avec <b>l'itinérance</b> de vos données mobiles (voix et data).</div>
            </v-col>
            <v-col :class="!$vuetify.breakpoint.mdAndDown ? 'center-y' : ''" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou3.jpg"/>
              <div class="my-4 text-h4 text-center">Convergence fixe/mobile</div>
              <div class="text-h5 text-center"><b>Embarquez</b> votre téléphonie fixe dans vos mobiles.</div>
              <div class="text-h5 text-center">Présentez votre <b>numéro fixe</b> depuis votre mobile et <b>améliorez</b> votre <b>joignabilité</b> et votre productivité.</div>
            </v-col>
            <v-col :class="!$vuetify.breakpoint.mdAndDown ? 'center-y' : ''" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto" :style="$vuetify.breakpoint.lgAndDown ? 'width: 100%;' : 'width: 500px;'" style="border-radius: 20px;" src="./assets/images/carou4.png"/>
              <div class="my-4 text-h4 text-center">Compagnon : l'appli de pilotage</div>
              <div class="text-h5 text-center"><b>Gérez</b> toutes vos communications.</div>
              <div class="text-h5 text-center">Transferts et <b>renvois</b> d'appels, <b>messagerie</b> vocale visuelle, <b>supervision</b> des collaborateurs et <b>annuaire</b> d'entreprise.</div>
            </v-col>
          </v-row>
        </div>
        <div class="pa-8 mt-8" :style="`background-color:#fee400`">
          <div class="text-h3 pb-6 red--text" style="line-height: inherit;">
            Avec OcciCom, bénéficiez d'avantages au-delà de la mobilité. 
          </div>
          <div class="text-h4 pb-6" style="line-height: inherit;">
            Des engagements pour vous <i class="red--text">accompagner</i> et <i class="red--text">améliorer</i> votre quotidien au travail.
          </div>
          
          <v-row>
            <v-col class="center-y" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/accompagnement.png"/>
              <div class="text-h5 text-center">En tant qu'opérateur <b>local</b>, nous sommes toujours <b>disponibles</b> et proches de vous pour la moindre demande.</div>
            </v-col>
            <v-col class="center-y" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/serenite.png"/>
              <div class="text-h5 text-center">Un service <b>d'accompagnement unique</b> de A à Z (de l'audit à l'installation et la maintenance de vos télécoms).</div>
            </v-col>
            <v-col class="center-y mt-4" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/simplicite.png"/>
              <div class="text-h5 text-center">Une offre globale, adaptée à vos besoins et un <b>meilleur contrôle</b> de vos communications grâce à la <b>supervision totale</b>.</div>
            </v-col>
            <v-col class="center-y mt-4" xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
              <img class="mx-auto mb-4" style="width: 150px; border-radius: 20px;" src="./assets/images/protection.png"/>
              <div class="text-h5 text-center">Profitez du <b>meilleur réseau</b> français.</div>
              <div class="text-h5 text-center"><b>Conservez</b> vos numéros, <b>restez joignables</b> sur tous vos appareils de communcation.</div>
            </v-col>
          </v-row>
        </div>
        <v-card v-if="$vuetify.breakpoint.mdAndDown" class="pa-4 mx-10 my-10">
          <div class="text-h4 text-center mt-8 mb-8">
            Envie d'augmenter votre mobilité ?<br/>Demandez votre audit gratuit.
          </div>
          <v-text-field v-model="entreprise" light solo label="Nom de la société"></v-text-field>
          <v-text-field v-model="nom" name="name" light solo label="Nom"></v-text-field>
          <v-text-field v-model="prenom" name="firstName" light solo label="Prénom"></v-text-field>
          <v-text-field v-model="email" name="email" type="email" light solo label="Email"></v-text-field>
          <v-text-field v-model="tel" name="phone" type="tel" light solo label="Téléphone"></v-text-field>
          <v-text-field v-model="phoneAmount" name="phoneAmount" type="number" light solo label="De combien de téléphones mobiles disposez-vous ?"></v-text-field>
          <v-checkbox v-model="checkbox">
            <template v-slot:label>
              <div>
                J'ai lu et j'accepte la
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://www.occicom.fr/"
                      @click.stop
                      v-on="on"
                    >
                      politique de confidentialité d'Occicom
                    </a>
                  </template>
                  Ouvrir dans un autre onglet
                </v-tooltip>
                en matière de protection des données à caractère personnel.
              </div>
            </template>
          </v-checkbox>
          <v-btn :disabled="!isValid" :loading="loading" block @click="send">S'inscrire</v-btn>
          <v-alert
            v-if="error"
            class="mt-4"
            type="error"
          >
            Il y a eu une erreur, veuillez réessayer plus tard
          </v-alert>
          <v-alert
            v-if="success"
            class="mt-4"
            type="success"
          >
            Sauvegarde réussite, merci de votre confiance !
          </v-alert>
        </v-card>
      </v-col>

      <v-col v-if="!$vuetify.breakpoint.mdAndDown" style="overflow: auto; height: 80vh;" xs="12" sm="12" md="12" lg="4" xl="4">
        <v-card class="pa-4">
          <div class="text-h4 text-center mt-8 mb-8">
            Envie d'augmenter votre mobilité ?<br/>Demandez votre audit gratuit.
          </div>
          <v-text-field v-model="entreprise" light solo label="Nom de la société"></v-text-field>
          <v-text-field v-model="nom" name="name" light solo label="Nom"></v-text-field>
          <v-text-field v-model="prenom" name="firstName" light solo label="Prénom"></v-text-field>
          <v-text-field v-model="email" name="email" type="email" light solo label="Email"></v-text-field>
          <v-text-field v-model="tel" name="phone" type="tel" light solo label="Téléphone"></v-text-field>
          <v-text-field v-model="phoneAmount" name="phoneAmount" type="number" light solo label="De combien de téléphones mobiles disposez-vous ?"></v-text-field>
          <v-checkbox v-model="checkbox">
            <template v-slot:label>
              <div>
                J'ai lu et j'accepte la
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://www.occicom.fr/"
                      @click.stop
                      v-on="on"
                    >
                      politique de confidentialité d'Occicom
                    </a>
                  </template>
                  Ouvrir dans un autre onglet
                </v-tooltip>
                en matière de protection des données à caractère personnel.
              </div>
            </template>
          </v-checkbox>
          <v-btn :disabled="!isValid" :loading="loading" block @click="send">S'inscrire</v-btn>
          <v-alert
            v-if="error"
            class="mt-4"
            type="error"
          >
            Il y a eu une erreur, veuillez réessayer plus tard
          </v-alert>
          <v-alert
            v-if="success"
            class="mt-4"
            type="success"
          >
            Sauvegarde réussite, merci de votre confiance !
          </v-alert>
        </v-card>
      </v-col>
      
    </v-row>
  </div>
</template>

<script>
import background from './assets/images/background.jpeg'

export default {
  components: { },
  name: 'mobilite-pro',

  data () {
    return {
      background: background,
      prenom: null,
      nom: null,
      tel: null,
      entreprise: null,
      email: null,
      phoneAmount: null,
      checkbox: false,
      loading: false,
      error: false,
      success: false
    }
  },

  computed: {
    isValid: function() {
      return (this.prenom != null && this.prenom.length > 1)
        && (this.nom != null && this.nom.length > 1)
        && (this.tel != null && this.tel.length > 1)
        && (this.entreprise != null && this.entreprise.length > 1)
        && (this.email != null && this.email.length > 1)
        && (this.phoneAmount != null)
        && this.checkbox == true
    }
  },

  methods: {
    send: function() {
      this.loading = true;
      this.error = this.success = false;
      
      this.$http.post(this.$env.api.host + "/formsData", {
        form: 2,
        title: "Mobilite pro",
        value: JSON.stringify(this.getValue())
      })
      .then(() => {
        this.loading = false;
        this.success = true;
        this.reset();
      })
      .catch((error) => {
        console.error(error);
        this.error = true;
        this.loading = false;
      });
    },
    getValue: function() {
      return {
        prenom: this.prenom,
        nom: this.nom,
        tel: this.tel,
        email: this.email,
        phoneAmount: this.phoneAmount,
        entreprise: this.entreprise,
        checkbox: this.checkbox
      }
    },
    reset: function() {
      this.prenom = null;
      this.nom = null;
      this.tel = null;
      this.entreprise = null;
      this.email = null;
      this.phoneAmount = null;
      this.checkbox = false;
    }
  }
};
</script>