<template>
  <v-card class="pa-8 h-100 center-y">
    <div class="text-center text-h6 transition-swing">
      {{ title }}
    </div>
    <v-dialog
      v-model="open"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ sub }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="open = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-carousel v-model="carouIndex" height="93vh">
          <v-carousel-item
            v-for="image in images"
            :key="image.index"
          >
            <div class="center-xy h-100">
              <img :src="image.src" style="max-height: 93vh; max-width: 100%;" />
            </div>
        </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'TutoVideo',

  props: {
    title: {
      type: String,
      default: ""
    },
    sub: {
      type: String,
      default: ""
    },
    images: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      open: false,
      carouIndex: 0
    }
  },
};
</script>